<template>
    <div v-if="comp" class="frm">
        <div class="page-header">管理员信息</div>
        <div class="">
            <CertState :comp="comp"></CertState>
        </div>
        <!-- v-if="comp.cert_stat==2" -->
        <div class="block">
            <div class="header">管理员信息</div>
            <div class="info-list">
                <div class="flex flex-align-center">
                    <label>管理员</label>
                    <span v-if="comp.principal_type==1">企业法定代表人</span>
                    <span v-if="comp.principal_type==2">企业授权代理人</span>
                    <span style="font-size: 12px;color: #B3B3B3;margin-left: 10px;">（签约时，需使用该代表/代理人的手机号码进行签约验证）</span>
                </div>
                <div class="flex flex-align-center">
                    <label>姓名</label>
                    <el-input size="small" v-if="editing" style="max-width:350px;" v-model="legal.legal_name"></el-input>
                    <template v-else>
                        <div v-if="comp.principal_type==2">{{comp.agent_name}}</div>
                        <div v-if="comp.principal_type==1">{{comp.legal_name}}</div>
                        <div v-if="!comp.agent_name && !comp.legal_name">--</div>
                    </template>
                </div>
                <div class="flex flex-align-center">
                    <label>联系方式</label>
                    <el-input v-if="editing" size="small" style="max-width:350px;" v-model="legal.legal_mobile"></el-input>
                    <template v-else>
                        <div v-if="comp.principal_type==2">{{comp.agent_mobile}}</div>
                        <div v-if="comp.principal_type==1">{{comp.legal_mobile}}</div>
                        <div v-if="!comp.agent_mobile && !comp.legal_mobile">--</div>
                    </template>
                </div>
                <div class="flex flex-align-center">
                    <label>身份证号</label>
                    <el-input size="small" v-if="editing" style="max-width:350px;" v-model="legal.legal_id_code"></el-input>
                    <template v-else>
                        <div v-if="comp.principal_type==2">{{comp.agent_id_code}}</div>
                        <div v-if="comp.principal_type==1">{{comp.legal_id_code}}</div>
                        <div v-if="!comp.agent_id_code && !comp.legal_id_code">--</div>
                    </template>
                </div>
                <!-- <div class="flex">
                    <label>证件照片</label>
                    <el-upload v-if="editing" class="comp-cert-uploader" action="xx" :show-file-list="false"
                        :accept="uploadAccept" :http-request="uploadLegal" :before-upload="beforeUpload">
                        <img v-if="comp.principal_type==1" :src="imgUrl+legal.legal_id_front" class="comp-cert-logo">
                        <img v-if="comp.principal_type==2" :src="imgUrl+legal.agent_id_front" class="comp-cert-logo">
                        <i v-else class="el-icon-plus comp-cert-logo-uploader-icon"></i>
                    </el-upload>
                    <div v-else
                        style="width:120px;height:120px;border:1px dashed rgba(204,204,204,1);overflow: hidden;display: inline-block;">
                        <img v-if="comp.principal_type==1" :src="imgUrl+comp.legal_id_front" style="width: 100%;height: 100%;">
                        <img v-if="comp.principal_type==2" :src="imgUrl+comp.agent_id_front" style="width: 100%;height: 100%;">
                        <img v-if="!comp.legal_id_front && comp.agent_id_front" :src="staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px'" style="width: 100%;height: 100%;">
                    </div>
                </div> -->
                <div v-if="editing" class="flex flex-justify-around">
                    <el-button size="small" @click="editing=false">取消</el-button>
                    <el-button size="small" type="warning" @click="save">保存</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { IMG_URL_PRE, STATIC_URL_PRE } from '@/config'
    import CertState from './CertState'
    import { getCompInfoById,setCompanyInfo } from '@/service/company';
    import { uploadFile } from '@/service/common';
    export default {
        components: {
            CertState
        },
        data() {
            return {
                staticUrl: STATIC_URL_PRE,
                imgUrl: IMG_URL_PRE,
                uploadAccept: 'image/jpeg,image/gif,image/png',
                comp: null,
                editing: false,
                legal: null
            }
        },
        async created() {
            this.comp = await getCompInfoById(this.$store.state.loginUser.comp.id);
            this.comp.agent_id_code = this.comp.agent_id_code.replace(/(\w{8})\w*(\w{2})/, '$1******$2');
            this.comp.legal_id_code = this.comp.legal_id_code.replace(/(\w{8})\w*(\w{2})/, '$1******$2');
        },
        methods: {
            edit(){
                this.legal={
                    comp_id:this.comp.id,
                    legal_name:this.comp.legal_name,
                    legal_id_code:this.comp.legal_id_code,
                    legal_mobile:this.comp.legal_mobile,
                    legal_id_front:this.comp.legal_id_front
                }
                this.editing=true;
            },
            save(){
                setCompanyInfo(this.legal).then(rst => {
                    if (rst.verify_url) {
                        window.open(rst.verify_url);
                    }
                    Object.assign(this.comp,this.legal);
                    this.editing=false;
                    this.$message.success('保存成功');
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            uploadLegal(item){
                uploadFile(item.file).then(url=>{
                    this.legal.legal_id_front=url;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            beforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传LOGO图片大小不能超过 10MB!');
                }
                return isLt2M;
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style>
    .comp-cert-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 120px;
        height: 120px;
    }

    .comp-cert-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .comp-cert-logo-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }

    .comp-cert-logo {
        width: 120px;
        height: 120px;
        display: block;
    }
</style>
<style scoped lang="less">
    .frm {
        height: 100%;
        overflow: auto;
        font-weight: 500;
    }
    .header {
        >div:first-child {
            font-weight: 600;
        }
    }

    .info-list {
        font-size: 14px;
        color: #808080;
        padding: 20px 0 20px 30px;
        >div {
            padding: 10px 0;
            >label:first-child {
                width: 56px;
                text-align: right;
                display: inline-block;
                vertical-align: top;
                margin-right: 20px;
                font-weight: bold;
                color: #4D4D4D;
            }
        }
    }

    .info-list>div {
        padding: 10px 0;
    }
</style>